var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    [
      _c(
        "CCol",
        { attrs: { col: "12" } },
        [
          _c(
            "CCard",
            [
              _c("CCardHeader", { staticStyle: { "font-weight": "bold" } }, [
                _vm._v(" " + _vm._s(_vm.itemVal.name) + " "),
                _c(
                  "div",
                  {
                    staticClass: "float-right",
                    staticStyle: { color: "#ff0000" },
                    on: { click: _vm.onRemoveItem }
                  },
                  [_c("CIcon", { attrs: { name: "cil-trash", size: "lg" } })],
                  1
                )
              ]),
              _c(
                "CCardBody",
                [
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        { staticClass: "divider", attrs: { lg: "5" } },
                        [
                          _c("CInput", {
                            attrs: { label: "Name" },
                            model: {
                              value: _vm.itemVal.name,
                              callback: function($$v) {
                                _vm.$set(_vm.itemVal, "name", $$v)
                              },
                              expression: "itemVal.name"
                            }
                          }),
                          _c("div", [_c("label", [_vm._v("Enabled?")])]),
                          _c("CSwitch", {
                            staticClass: "mr-1",
                            attrs: {
                              color: "success",
                              shape: "pill",
                              checked: _vm.itemVal.item_enabled
                            },
                            on: {
                              "update:checked": function($event) {
                                return _vm.$set(
                                  _vm.itemVal,
                                  "item_enabled",
                                  $event
                                )
                              }
                            }
                          }),
                          _c("CInput", {
                            attrs: { label: "Prep Time", append: "min" },
                            model: {
                              value: _vm.itemVal.prep_time,
                              callback: function($$v) {
                                _vm.$set(_vm.itemVal, "prep_time", $$v)
                              },
                              expression: "itemVal.prep_time"
                            }
                          }),
                          _c("CInput", {
                            attrs: { label: "Price", prepend: "$" },
                            model: {
                              value: _vm.itemVal.price,
                              callback: function($$v) {
                                _vm.$set(_vm.itemVal, "price", $$v)
                              },
                              expression: "itemVal.price"
                            }
                          }),
                          _c("CInput", {
                            attrs: { label: "Sub-Category" },
                            model: {
                              value: _vm.itemVal.category,
                              callback: function($$v) {
                                _vm.$set(_vm.itemVal, "category", $$v)
                              },
                              expression: "itemVal.category"
                            }
                          }),
                          _c("CTextarea", {
                            attrs: { label: "Description", type: "multiline" },
                            model: {
                              value: _vm.itemVal.description,
                              callback: function($$v) {
                                _vm.$set(_vm.itemVal, "description", $$v)
                              },
                              expression: "itemVal.description"
                            }
                          }),
                          _c("label", [_vm._v("Item Image")]),
                          _c("br"),
                          !_vm.isImageEdited
                            ? _c("CImg", {
                                attrs: {
                                  src: _vm.itemVal.image_url,
                                  fluid: "",
                                  thumbnail: ""
                                }
                              })
                            : _vm._e(),
                          _vm.isImageEdited
                            ? _c("CImg", {
                                attrs: {
                                  src: _vm.imageData,
                                  fluid: "",
                                  thumbnail: ""
                                }
                              })
                            : _vm._e(),
                          _c("br"),
                          _c("CInputFile", {
                            staticClass: "pl-1 pt-2",
                            attrs: { horizontal: "", custom: "" },
                            on: { change: _vm.onFileSelected }
                          })
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        { attrs: { lg: "7" } },
                        [
                          _c("h4", [_vm._v("Options")]),
                          _c(
                            "CRow",
                            { staticClass: "tiny-header" },
                            [
                              _c("CCol", { attrs: { col: "1" } }),
                              _c("CCol", { attrs: { col: "3" } }, [
                                _vm._v("Name")
                              ]),
                              _c("CCol", { attrs: { col: "3" } }, [
                                _vm._v("Req./Opt.")
                              ]),
                              _c("CCol", { attrs: { col: "2" } }, [
                                _vm._v("Min")
                              ]),
                              _c("CCol", { attrs: { col: "2" } }, [
                                _vm._v("Max")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "draggable",
                            _vm._b(
                              {
                                on: {
                                  start: function($event) {
                                    _vm.drag = true
                                  },
                                  end: function($event) {
                                    _vm.drag = false
                                  }
                                },
                                model: {
                                  value: _vm.itemVal.options,
                                  callback: function($$v) {
                                    _vm.$set(_vm.itemVal, "options", $$v)
                                  },
                                  expression: "itemVal.options"
                                }
                              },
                              "draggable",
                              _vm.dragOptions,
                              false
                            ),
                            [
                              _c(
                                "transition-group",
                                {
                                  attrs: {
                                    type: "transition",
                                    name: !_vm.drag ? "flip-list" : null
                                  }
                                },
                                _vm._l(_vm.itemVal.options, function(
                                  option,
                                  optionIndex
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: option.category_number,
                                      attrs: { value: option.category_number }
                                    },
                                    [
                                      _c(
                                        "CRow",
                                        {
                                          staticClass: "menu-item small-text",
                                          staticStyle: {
                                            "border-bottom":
                                              "1px solid lightgray"
                                          }
                                        },
                                        [
                                          _vm.isCollapsed !=
                                          "optionCategory" + optionIndex
                                            ? _c(
                                                "CCol",
                                                {
                                                  attrs: { col: "1" },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.isCollapsed =
                                                        "optionCategory" +
                                                        optionIndex
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("CIcon", {
                                                    attrs: {
                                                      name: "cis-caret-right",
                                                      size: "sm"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.isCollapsed ==
                                          "optionCategory" + optionIndex
                                            ? _c(
                                                "CCol",
                                                {
                                                  attrs: { col: "1" },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.isCollapsed = ""
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("CIcon", {
                                                    attrs: {
                                                      name: "cis-caret-bottom",
                                                      size: "sm"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "CCol",
                                            { attrs: { col: "3" } },
                                            [
                                              _vm.isEditing ==
                                              "optionCategory" + optionIndex
                                                ? _c("CInput", {
                                                    directives: [
                                                      {
                                                        name: "focus",
                                                        rawName: "v-focus"
                                                      }
                                                    ],
                                                    on: {
                                                      blur: function($event) {
                                                        return _vm.handleBlur(
                                                          "optionCategory" +
                                                            optionIndex
                                                        )
                                                      },
                                                      keyup: function($event) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        ) {
                                                          return null
                                                        }
                                                        _vm.isEditing =
                                                          "optionType" +
                                                          optionIndex
                                                      },
                                                      keydown: function(
                                                        $event
                                                      ) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "tab",
                                                            9,
                                                            $event.key,
                                                            "Tab"
                                                          )
                                                        ) {
                                                          return null
                                                        }
                                                        $event.preventDefault()
                                                        _vm.isEditing =
                                                          "optionType" +
                                                          optionIndex
                                                      }
                                                    },
                                                    model: {
                                                      value: option.category,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          option,
                                                          "category",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "option.category"
                                                    }
                                                  })
                                                : _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            _vm.isEditing =
                                                              "optionCategory" +
                                                              optionIndex
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              option.category
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "CCol",
                                            {
                                              staticClass: "small-text",
                                              attrs: { col: "3" }
                                            },
                                            [
                                              _vm.isEditing ==
                                              "optionType" + optionIndex
                                                ? _c("CSelect", {
                                                    directives: [
                                                      {
                                                        name: "focus",
                                                        rawName: "v-focus"
                                                      }
                                                    ],
                                                    attrs: {
                                                      placeholder: "-- Type --",
                                                      value: option.type,
                                                      options: [
                                                        {
                                                          value: "Required",
                                                          label: "Required"
                                                        },
                                                        {
                                                          value: "Optional",
                                                          label: "Optional"
                                                        }
                                                      ]
                                                    },
                                                    on: {
                                                      "update:value": function(
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          option,
                                                          "type",
                                                          $event
                                                        )
                                                      },
                                                      blur: function($event) {
                                                        return _vm.handleBlur(
                                                          "optionType" +
                                                            optionIndex
                                                        )
                                                      },
                                                      change: function($event) {
                                                        _vm.isEditing =
                                                          "optionMin" +
                                                          optionIndex
                                                      },
                                                      keydown: function(
                                                        $event
                                                      ) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "tab",
                                                            9,
                                                            $event.key,
                                                            "Tab"
                                                          )
                                                        ) {
                                                          return null
                                                        }
                                                        $event.preventDefault()
                                                        _vm.isEditing =
                                                          "optionMin" +
                                                          optionIndex
                                                      }
                                                    }
                                                  })
                                                : _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            _vm.isEditing =
                                                              "optionType" +
                                                              optionIndex
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              option.type
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "CCol",
                                            { attrs: { col: "2" } },
                                            [
                                              _vm.isEditing ==
                                              "optionMin" + optionIndex
                                                ? _c("CSelect", {
                                                    directives: [
                                                      {
                                                        name: "focus",
                                                        rawName: "v-focus"
                                                      }
                                                    ],
                                                    attrs: {
                                                      placeholder: "Min",
                                                      value: option.min,
                                                      options: [
                                                        {
                                                          value: "0",
                                                          label: "0"
                                                        },
                                                        {
                                                          value: "1",
                                                          label: "1"
                                                        },
                                                        {
                                                          value: "2",
                                                          label: "2"
                                                        },
                                                        {
                                                          value: "3",
                                                          label: "3"
                                                        },
                                                        {
                                                          value: "4",
                                                          label: "4"
                                                        },
                                                        {
                                                          value: "5",
                                                          label: "5"
                                                        },
                                                        {
                                                          value: "6",
                                                          label: "6"
                                                        },
                                                        {
                                                          value: "7",
                                                          label: "7"
                                                        },
                                                        {
                                                          value: "8",
                                                          label: "8"
                                                        },
                                                        {
                                                          value: "9",
                                                          label: "9"
                                                        },
                                                        {
                                                          value: "10",
                                                          label: "10"
                                                        }
                                                      ]
                                                    },
                                                    on: {
                                                      "update:value": function(
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          option,
                                                          "min",
                                                          $event
                                                        )
                                                      },
                                                      blur: function($event) {
                                                        return _vm.handleBlur(
                                                          "optionMin" +
                                                            optionIndex
                                                        )
                                                      },
                                                      change: function($event) {
                                                        _vm.isEditing =
                                                          "optionMax" +
                                                          optionIndex
                                                      },
                                                      keydown: function(
                                                        $event
                                                      ) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "tab",
                                                            9,
                                                            $event.key,
                                                            "Tab"
                                                          )
                                                        ) {
                                                          return null
                                                        }
                                                        if (
                                                          $event.ctrlKey ||
                                                          $event.shiftKey ||
                                                          $event.altKey ||
                                                          $event.metaKey
                                                        ) {
                                                          return null
                                                        }
                                                        _vm.isEditing =
                                                          "optionMax" +
                                                          optionIndex
                                                      }
                                                    }
                                                  })
                                                : _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            _vm.isEditing =
                                                              "optionMin" +
                                                              optionIndex
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(option.min) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "CCol",
                                            { attrs: { col: "2" } },
                                            [
                                              _vm.isEditing ==
                                              "optionMax" + optionIndex
                                                ? _c("CSelect", {
                                                    directives: [
                                                      {
                                                        name: "focus",
                                                        rawName: "v-focus"
                                                      }
                                                    ],
                                                    attrs: {
                                                      placeholder: "Max",
                                                      value: option.max,
                                                      options: [
                                                        {
                                                          value: "0",
                                                          label: "0"
                                                        },
                                                        {
                                                          value: "1",
                                                          label: "1"
                                                        },
                                                        {
                                                          value: "2",
                                                          label: "2"
                                                        },
                                                        {
                                                          value: "3",
                                                          label: "3"
                                                        },
                                                        {
                                                          value: "4",
                                                          label: "4"
                                                        },
                                                        {
                                                          value: "5",
                                                          label: "5"
                                                        },
                                                        {
                                                          value: "6",
                                                          label: "6"
                                                        },
                                                        {
                                                          value: "7",
                                                          label: "7"
                                                        },
                                                        {
                                                          value: "8",
                                                          label: "8"
                                                        },
                                                        {
                                                          value: "9",
                                                          label: "9"
                                                        },
                                                        {
                                                          value: "10",
                                                          label: "10"
                                                        }
                                                      ]
                                                    },
                                                    on: {
                                                      "update:value": function(
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          option,
                                                          "max",
                                                          $event
                                                        )
                                                      },
                                                      blur: function($event) {
                                                        return _vm.handleBlur(
                                                          "optionMax" +
                                                            optionIndex
                                                        )
                                                      },
                                                      change: function($event) {
                                                        return $event.target.blur()
                                                      }
                                                    }
                                                  })
                                                : _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            _vm.isEditing =
                                                              "optionMax" +
                                                              optionIndex
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(option.max) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "CCol",
                                            {
                                              staticClass: "text-right",
                                              attrs: { lg: "1" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.onOptionsDelete(
                                                    option
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("CIcon", {
                                                attrs: {
                                                  name: "cil-trash",
                                                  size: "sm"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "CCollapse",
                                        {
                                          attrs: {
                                            show:
                                              _vm.isCollapsed ===
                                              "optionCategory" + optionIndex
                                          }
                                        },
                                        [
                                          _c(
                                            "draggable",
                                            _vm._b(
                                              {
                                                on: {
                                                  start: function($event) {
                                                    _vm.drag = true
                                                  },
                                                  end: function($event) {
                                                    _vm.drag = false
                                                  }
                                                },
                                                model: {
                                                  value: option.items,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      option,
                                                      "items",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "option.items"
                                                }
                                              },
                                              "draggable",
                                              _vm.dragSubOptions,
                                              false
                                            ),
                                            [
                                              _c(
                                                "transition-group",
                                                {
                                                  attrs: {
                                                    type: "transition",
                                                    name: !_vm.drag
                                                      ? "flip-list"
                                                      : null
                                                  }
                                                },
                                                _vm._l(option.items, function(
                                                  item,
                                                  itemIndex
                                                ) {
                                                  return _c(
                                                    "CRow",
                                                    {
                                                      key: item.item_number,
                                                      staticClass:
                                                        "menu-item small-text p-1 pr-2",
                                                      attrs: {
                                                        value: item.item_number
                                                      }
                                                    },
                                                    [
                                                      _c("CCol", {
                                                        attrs: { col: "1" }
                                                      }),
                                                      _c(
                                                        "CCol",
                                                        { attrs: { col: "7" } },
                                                        [
                                                          _vm.isEditing ==
                                                          "option" +
                                                            optionIndex +
                                                            "itemName" +
                                                            itemIndex
                                                            ? _c("CInput", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "focus",
                                                                    rawName:
                                                                      "v-focus"
                                                                  }
                                                                ],
                                                                on: {
                                                                  blur: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleBlur(
                                                                      "option" +
                                                                        optionIndex +
                                                                        "itemName" +
                                                                        itemIndex
                                                                    )
                                                                  },
                                                                  keyup: function(
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    ) {
                                                                      return null
                                                                    }
                                                                    _vm.isEditing =
                                                                      "option" +
                                                                      optionIndex +
                                                                      "itemPrice" +
                                                                      itemIndex
                                                                  },
                                                                  keydown: function(
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "tab",
                                                                        9,
                                                                        $event.key,
                                                                        "Tab"
                                                                      )
                                                                    ) {
                                                                      return null
                                                                    }
                                                                    $event.preventDefault()
                                                                    _vm.isEditing =
                                                                      "option" +
                                                                      optionIndex +
                                                                      "itemPrice" +
                                                                      itemIndex
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    item.name,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "name",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "item.name"
                                                                }
                                                              })
                                                            : _c("div", [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        _vm.isEditing =
                                                                          "option" +
                                                                          optionIndex +
                                                                          "itemName" +
                                                                          itemIndex
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          item.name
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ]
                                                                )
                                                              ])
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "CCol",
                                                        { attrs: { col: "3" } },
                                                        [
                                                          _vm.isEditing ==
                                                          "option" +
                                                            optionIndex +
                                                            "itemPrice" +
                                                            itemIndex
                                                            ? _c("CInput", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "focus",
                                                                    rawName:
                                                                      "v-focus"
                                                                  }
                                                                ],
                                                                attrs: {
                                                                  prepend: "$"
                                                                },
                                                                on: {
                                                                  blur: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleBlur(
                                                                      "option" +
                                                                        optionIndex +
                                                                        "itemPrice" +
                                                                        itemIndex
                                                                    )
                                                                  },
                                                                  keyup: function(
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    ) {
                                                                      return null
                                                                    }
                                                                    return $event.target.blur()
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    item.price,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "price",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "item.price"
                                                                }
                                                              })
                                                            : _c("div", [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        _vm.isEditing =
                                                                          "option" +
                                                                          optionIndex +
                                                                          "itemPrice" +
                                                                          itemIndex
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " $" +
                                                                        _vm._s(
                                                                          item.price
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ]
                                                                )
                                                              ])
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "CCol",
                                                        {
                                                          staticClass:
                                                            "text-right",
                                                          attrs: { col: "1" },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.onSubOptionsDelete(
                                                                item
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("CIcon", {
                                                            attrs: {
                                                              name: "cil-trash",
                                                              size: "sm"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                }),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "CRow",
                                            {
                                              staticClass:
                                                "menu-item small-text p-1 pr-2"
                                            },
                                            [
                                              _c("CCol", {
                                                attrs: { col: "1" }
                                              }),
                                              _c(
                                                "CCol",
                                                {
                                                  staticClass: "text-light",
                                                  attrs: { col: "7" }
                                                },
                                                [
                                                  _vm.isEditing ==
                                                  "option" +
                                                    optionIndex +
                                                    "itemNameNEW"
                                                    ? _c("CInput", {
                                                        directives: [
                                                          {
                                                            name: "focus",
                                                            rawName: "v-focus"
                                                          }
                                                        ],
                                                        attrs: {
                                                          placeholder:
                                                            "Add A New Sub-Option"
                                                        },
                                                        on: {
                                                          blur: function(
                                                            $event
                                                          ) {
                                                            return _vm.handleBlur(
                                                              "option" +
                                                                optionIndex +
                                                                "itemNameNEW"
                                                            )
                                                          },
                                                          keyup: function(
                                                            $event
                                                          ) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "enter",
                                                                13,
                                                                $event.key,
                                                                "Enter"
                                                              )
                                                            ) {
                                                              return null
                                                            }
                                                            _vm.isEditing =
                                                              "option" +
                                                              optionIndex +
                                                              "itemPriceNEW"
                                                          },
                                                          keydown: function(
                                                            $event
                                                          ) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "tab",
                                                                9,
                                                                $event.key,
                                                                "Tab"
                                                              )
                                                            ) {
                                                              return null
                                                            }
                                                            $event.preventDefault()
                                                            _vm.isEditing =
                                                              "option" +
                                                              optionIndex +
                                                              "itemPriceNEW"
                                                          }
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newItem.name,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newItem,
                                                              "name",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newItem.name"
                                                        }
                                                      })
                                                    : _c("div", [
                                                        _c(
                                                          "div",
                                                          {
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                _vm.isEditing =
                                                                  "option" +
                                                                  optionIndex +
                                                                  "itemNameNEW"
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm.newItem.name ===
                                                            ""
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    " Add A New Sub-Option "
                                                                  )
                                                                ])
                                                              : _c("span", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .newItem
                                                                          .name
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ])
                                                          ]
                                                        )
                                                      ])
                                                ],
                                                1
                                              ),
                                              _c(
                                                "CCol",
                                                {
                                                  staticClass: "text-light",
                                                  attrs: { col: "3" }
                                                },
                                                [
                                                  _vm.isEditing ==
                                                  "option" +
                                                    optionIndex +
                                                    "itemPriceNEW"
                                                    ? _c("CInput", {
                                                        directives: [
                                                          {
                                                            name: "focus",
                                                            rawName: "v-focus"
                                                          }
                                                        ],
                                                        attrs: { prepend: "$" },
                                                        on: {
                                                          blur: function(
                                                            $event
                                                          ) {
                                                            return _vm.handleBlur(
                                                              "option" +
                                                                optionIndex +
                                                                "itemPriceNEW"
                                                            )
                                                          },
                                                          keyup: function(
                                                            $event
                                                          ) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "enter",
                                                                13,
                                                                $event.key,
                                                                "Enter"
                                                              )
                                                            ) {
                                                              return null
                                                            }
                                                            return $event.target.blur()
                                                          }
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newItem.price,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newItem,
                                                              "price",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newItem.price"
                                                        }
                                                      })
                                                    : _c("div", [
                                                        _c(
                                                          "div",
                                                          {
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                _vm.isEditing =
                                                                  "option" +
                                                                  optionIndex +
                                                                  "itemPriceNEW"
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " $" +
                                                                _vm._s(
                                                                  _vm.newItem
                                                                    .price
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ])
                                                ],
                                                1
                                              ),
                                              _c(
                                                "CCol",
                                                {
                                                  staticClass: "text-right",
                                                  attrs: { col: "1" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.saveSubItems(
                                                        option
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("CIcon", {
                                                    attrs: {
                                                      name: "cil-save",
                                                      size: "sm"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            ],
                            1
                          ),
                          _c(
                            "CRow",
                            {
                              staticClass: "menu-item small-text",
                              staticStyle: {
                                "border-top": "1px solid lightgray"
                              }
                            },
                            [
                              _c(
                                "CCol",
                                {
                                  staticClass: "text-light",
                                  attrs: { col: "4" }
                                },
                                [
                                  _vm.isEditing === "optionCategoryNEW"
                                    ? _c("CInput", {
                                        directives: [
                                          { name: "focus", rawName: "v-focus" }
                                        ],
                                        attrs: {
                                          placeholder: "Add A New Option"
                                        },
                                        on: {
                                          blur: function($event) {
                                            return _vm.handleBlur(
                                              "optionCategoryNEW"
                                            )
                                          },
                                          keyup: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            _vm.isEditing = "optionTypeNEW"
                                          },
                                          keydown: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "tab",
                                                9,
                                                $event.key,
                                                "Tab"
                                              )
                                            ) {
                                              return null
                                            }
                                            $event.preventDefault()
                                            _vm.isEditing = "optionTypeNEW"
                                          }
                                        },
                                        model: {
                                          value: _vm.newOption.category,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.newOption,
                                              "category",
                                              $$v
                                            )
                                          },
                                          expression: "newOption.category"
                                        }
                                      })
                                    : _c("div", [
                                        _c(
                                          "div",
                                          {
                                            on: {
                                              click: function($event) {
                                                _vm.isEditing =
                                                  "optionCategoryNEW"
                                              }
                                            }
                                          },
                                          [
                                            _vm.newOption.category === ""
                                              ? _c("span", [
                                                  _vm._v(" Add A New Option ")
                                                ])
                                              : _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.newOption.category
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                          ]
                                        )
                                      ])
                                ],
                                1
                              ),
                              _c(
                                "CCol",
                                {
                                  staticClass: "text-light",
                                  attrs: { col: "3" }
                                },
                                [
                                  _vm.isEditing === "optionTypeNEW"
                                    ? _c("CSelect", {
                                        directives: [
                                          { name: "focus", rawName: "v-focus" }
                                        ],
                                        attrs: {
                                          placeholder: "-- Type --",
                                          value: _vm.newOption.type,
                                          options: [
                                            {
                                              value: "Required",
                                              label: "Required"
                                            },
                                            {
                                              value: "Optional",
                                              label: "Optional"
                                            }
                                          ]
                                        },
                                        on: {
                                          "update:value": function($event) {
                                            return _vm.$set(
                                              _vm.newOption,
                                              "type",
                                              $event
                                            )
                                          },
                                          blur: function($event) {
                                            return _vm.handleBlur(
                                              "optionTypeNEW"
                                            )
                                          },
                                          change: function($event) {
                                            _vm.isEditing = "optionMinNEW"
                                          },
                                          keydown: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "tab",
                                                9,
                                                $event.key,
                                                "Tab"
                                              )
                                            ) {
                                              return null
                                            }
                                            $event.preventDefault()
                                            _vm.isEditing = "optionMinNEW"
                                          }
                                        }
                                      })
                                    : _c("div", [
                                        _c(
                                          "div",
                                          {
                                            on: {
                                              click: function($event) {
                                                _vm.isEditing = "optionTypeNEW"
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.newOption.type) +
                                                " "
                                            )
                                          ]
                                        )
                                      ])
                                ],
                                1
                              ),
                              _c(
                                "CCol",
                                {
                                  staticClass: "text-light",
                                  attrs: { col: "2" }
                                },
                                [
                                  _vm.isEditing == "optionMinNEW"
                                    ? _c("CSelect", {
                                        directives: [
                                          { name: "focus", rawName: "v-focus" }
                                        ],
                                        attrs: {
                                          placeholder: "Min",
                                          value: _vm.newOption.min,
                                          options: [
                                            { value: "0", label: "0" },
                                            { value: "1", label: "1" },
                                            { value: "2", label: "2" },
                                            { value: "3", label: "3" },
                                            { value: "4", label: "4" },
                                            { value: "5", label: "5" },
                                            { value: "6", label: "6" },
                                            { value: "7", label: "7" },
                                            { value: "8", label: "8" },
                                            { value: "9", label: "9" },
                                            { value: "10", label: "10" }
                                          ]
                                        },
                                        on: {
                                          "update:value": function($event) {
                                            return _vm.$set(
                                              _vm.newOption,
                                              "min",
                                              $event
                                            )
                                          },
                                          blur: function($event) {
                                            return _vm.handleBlur(
                                              "optionMinNEW"
                                            )
                                          },
                                          change: function($event) {
                                            _vm.isEditing = "optionMaxNEW"
                                          },
                                          keydown: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "tab",
                                                9,
                                                $event.key,
                                                "Tab"
                                              )
                                            ) {
                                              return null
                                            }
                                            $event.preventDefault()
                                            _vm.isEditing = "optionMaxNEW"
                                          }
                                        }
                                      })
                                    : _c("div", [
                                        _c(
                                          "div",
                                          {
                                            on: {
                                              click: function($event) {
                                                _vm.isEditing = "optionMinNEW"
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.newOption.min) +
                                                " "
                                            )
                                          ]
                                        )
                                      ])
                                ],
                                1
                              ),
                              _c(
                                "CCol",
                                {
                                  staticClass: "text-light",
                                  attrs: { col: "2" }
                                },
                                [
                                  _vm.isEditing == "optionMaxNEW"
                                    ? _c("CSelect", {
                                        directives: [
                                          { name: "focus", rawName: "v-focus" }
                                        ],
                                        attrs: {
                                          placeholder: "Max",
                                          value: _vm.newOption.max,
                                          options: [
                                            { value: "0", label: "0" },
                                            { value: "1", label: "1" },
                                            { value: "2", label: "2" },
                                            { value: "3", label: "3" },
                                            { value: "4", label: "4" },
                                            { value: "5", label: "5" },
                                            { value: "6", label: "6" },
                                            { value: "7", label: "7" },
                                            { value: "8", label: "8" },
                                            { value: "9", label: "9" },
                                            { value: "10", label: "10" }
                                          ]
                                        },
                                        on: {
                                          "update:value": function($event) {
                                            return _vm.$set(
                                              _vm.newOption,
                                              "max",
                                              $event
                                            )
                                          },
                                          blur: function($event) {
                                            return _vm.handleBlur(
                                              "optionMaxNEW"
                                            )
                                          },
                                          change: function($event) {
                                            return $event.target.blur()
                                          }
                                        }
                                      })
                                    : _c("div", [
                                        _c(
                                          "div",
                                          {
                                            on: {
                                              click: function($event) {
                                                _vm.isEditing = "optionMaxNEW"
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.newOption.max) +
                                                " "
                                            )
                                          ]
                                        )
                                      ])
                                ],
                                1
                              ),
                              _c(
                                "CCol",
                                {
                                  staticClass: "text-right",
                                  attrs: { lg: "1" },
                                  on: { click: _vm.saveOptions }
                                },
                                [
                                  _c("CIcon", {
                                    attrs: { name: "cil-save", size: "sm" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "CCardFooter",
                [
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        { attrs: { sm: "6" } },
                        [
                          _c(
                            "CButton",
                            {
                              attrs: { color: "light" },
                              on: { click: _vm.goBack }
                            },
                            [_vm._v("Cancel")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        { staticClass: "text-right", attrs: { sm: "6" } },
                        [
                          _c(
                            "CButton",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.onSaveEditClick }
                            },
                            [_vm._v("Save")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }